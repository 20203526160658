import * as React from "react"

const GridView = ({ ...props }) => {
  return (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth="0.5"
        d="M10.614 1a.75.75 0 01.75-.75H18a.75.75 0 01.75.75v6.636a.75.75 0 01-.75.75h-6.636a.75.75 0 01-.75-.75V1zm0 10.364a.75.75 0 01.75-.75H18a.75.75 0 01.75.75V18a.75.75 0 01-.75.75h-6.636a.75.75 0 01-.75-.75v-6.636zM.25 1A.75.75 0 011 .25h6.636a.75.75 0 01.75.75v6.636a.75.75 0 01-.75.75H1a.75.75 0 01-.75-.75V1zm0 10.364a.75.75 0 01.75-.75h6.636a.75.75 0 01.75.75V18a.75.75 0 01-.75.75H1A.75.75 0 01.25 18v-6.636z"
      ></path>
    </svg>
  )
}
export default GridView

