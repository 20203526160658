import { connect } from 'react-redux'
import { getLtweeklyData, SavedLTWebsiteAnalytics, updateCurrentPosition, initHomePhase,getLatestLTWeeklyNews,sendPollAnswer,getPollResult } from '../../store/home/duck'

import HomeSidebarComponent from "./component";

const HomeSidebarContainer = connect(
  // Map state to props
  (state) => ({
    ltweeklyData: state.home.ltweeklyData,
    totalLtweeklyData: state.home.totalLtweeklyData,
    ltweeklyPhase: state.home.ltweeklyPhase,
    currentPosition: state.home.currentPosition,
    totalPageNumber: state.home.totalPageNumber,
    ltweeklyTotalRecords: state.home.ltweeklyTotalRecords,
  }),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    updateCurrentPosition,
    getLtweeklyData,
    initHomePhase,
    getLatestLTWeeklyNews,
    sendPollAnswer,
    getPollResult
  }
)(HomeSidebarComponent);

export default HomeSidebarContainer
