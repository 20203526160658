import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics, getResources, initHomePhase, getltWeeklyByDate, getLtWeeklyDetailsById, validateUser, initPhase,ValidateLink } from '../../store/home/duck'
import WeeklyDetailComponent from './component'

const WeeklyDetailContainer = connect(
  // Map state to props
  (/*state*/) => ({
    
  }),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    validateUser,
    getltWeeklyByDate,
    getResources,
    initHomePhase,
    initPhase,
    getLtWeeklyDetailsById,
    ValidateLink
  }
)(WeeklyDetailComponent)

export default WeeklyDetailContainer
