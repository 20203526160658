import React, { useEffect, useState } from "react";
import Leslie from "../../images/leslie.png";
import Zaina from "../../images/Shariff_Zaina.png";
import Matthew from "../../images/matthew.png";
import moment from "moment";
import { browserName } from "react-device-detect";
import { getDevice } from "../../constants/constant";
import "./styles.scss";

export default function AboutComponent(props) {
  const [isMounted, setIsMounted] = useState(false);

  const handledClicked = (actionDescription) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(itemDate, "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "pageview",
      NewsletterID: "",
      NewsletterDate: "",
      ThemeID: "",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (!isMounted) {
      handledClicked("About Page");
      setIsMounted(true);
    }
  }, []);
  return (
    <>
      <section className="section__team--bio">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="story__container">
                <div className="story__wrap">
                  <div className="story__profile">
                    <img src={Leslie} alt="" />
                  </div>
                  <div className="story__info">
                    <h2 className="story__name">Leslie Mallon</h2>
                    <h4 className="story__title">Head of Public Markets</h4>
                    <div className="story__bio">
                      <p>
                        Leslie Mallon heads LionTree’s Public Markets business
                        which provides advisory services to companies aiming to
                        realize a public stock listing and also works with large
                        asset managers regarding their public equity and pre-IPO
                        investments within the sector.
                      </p>
                      <p>
                        Prior to joining LionTree in late 2015, she was a
                        Managing Director at UBS, where she co-head NY
                        Institutional Equity Sales as well as led the firm’s
                        Technology, Media, and Telecom Equity Sales effort
                      </p>
                      <p>
                        Previous to UBS, Leslie was in Equity Research at Credit
                        Suisse covering Optical Component and Systems Companies.
                        She has a Bachelor of Science from Carnegie-Mellon
                        University and an M.B.A from Columbia Business School.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="story__heading">
                  <h5 className="story__heading--title">Team</h5>
                </div>
                <div className="team__grid">
                  {/* <div className="team__grid--item">
                    <div className="team__grid--card">
                      <div className="team__grid--picture">
                        <img src={Amine} alt="" />
                      </div>
                      <div className="team__grid--info">
                        <h2 className="team__name">Amine Bensaid</h2>
                        <h4 className="team__title">VP, Public Markets</h4>
                        <p className="team__bio">
                          Amine Bensaid joined LionTree in 2022. Prior to that,
                          Amine was an equity research analyst at Bloomberg
                          Intelligence, covering video games, digital media, and
                          IT infrastructure software. Amine earned his Master’s
                          in Finance from Johns Hopkins University in 2014.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="team__grid--item">
                    <div className="team__grid--card">
                      <div className="team__grid--picture">
                        <img src={Zaina} alt="" />
                      </div>
                      <div className="team__grid--info">
                        <h2 className="team__name">Zaina Shariff</h2>
                        <h4 className="team__title">Analyst, Public Markets</h4>
                        <p className="team__bio">
                          Zaina Shariff joined LionTree’s Public Markets
                          business in 2021. She has a Bachelor of Arts from New
                          York University.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="team__grid--item">
                    <div className="team__grid--card">
                      <div className="team__grid--picture">
                        <img src={Matthew} alt="" />
                      </div>
                      <div className="team__grid--info">
                        <h2 className="team__name">Matthew Ching</h2>
                        <h4 className="team__title">Analyst, Public Markets</h4>
                        <p className="team__bio">
                          Matt Ching joins the Public Markets team as a Research
                          Content Analyst. Before joining LionTree, Matt was
                          previously a Research Analyst at Point72. Matt
                          received his bachelor’s degree in Finance from
                          Georgetown University in 2018, where he also studied
                          English literature.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
