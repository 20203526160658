import React, { useState, useEffect } from "react";
import moment from "moment";
import { get, isEmpty } from "lodash";
import LogoTwo from "../../icons/LogoTwo";
import "./styles.scss";
import { browserName } from "react-device-detect";
import { getDevice } from "../../../constants/constant";
import CookieConsentCall from "../../../views/PrivacyPolicy/coookieConsentPage";
import IcLinked from "../../../images/linkedin-icon.svg";
import IcInsta from "../../../images/instagram-icon.svg";
import { Navigate } from "react-router";
import { Link, useNavigate } from "react-router-dom";

export default function FooterComponent(props) {
  let year = new Date().getFullYear();
  const [resourceUrl, setResourceUrl] = useState("");
  const Navigate = useNavigate();

  useEffect(async () => {
    const callApi = async () => {
      await props.getResources();
    };
    if (!(get(props, "resourceData", []).length > 0)) {
      callApi();
    } else {
      if (!resourceUrl) {
        let obj =
          get(props, "resourceData", []).length > 0
            ? get(props, "resourceData", []).find(
                (data) => data.ItemName === "Privacy"
              )
            : {};
        if (!isEmpty(obj)) {
          setResourceUrl(get(obj, "ResourceUrl", ""));
        }
      }
    }
    if (get(props, "resourcePhase", []) === "success") {
      await props.initPhase();
      let obj =
        get(props, "resourceData", []).length > 0
          ? get(props, "resourceData", []).find(
              (data) => data.ItemName === "Privacy"
            )
          : {};
      if (!isEmpty(obj)) {
        setResourceUrl(get(obj, "ResourceUrl", ""));
      }
    }
  }, [resourceUrl, props]);

  const handledClicked = (actionDescription, val) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate = itemDate
      ? moment(itemDate, "MM DD YYYY").format("MMMM DD, YYYY")
      : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      // Url: val.replace(/[https://http://]/g,''),
      ActionType: "clicked",
      NewsletterID: get(props, "ltWeeklyItemData.ItemId", ""),
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
    };

    if (actionDescription === "Privacy Policy") {
      props.SavedLTWebsiteAnalytics(obj);
    }
    window.open(val, "_blank");
  };
  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      <footer id="newpagefooter" className="footerlight">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="left-content">
                <h2>LIONTREE</h2>
                <p>
                  The independent investment <br />
                  and merchant bank for creativity,
                  <br />
                  community, and capital
                </p>
                <img
                  src="https://liontree.com/wp-content/uploads/2023/05/leaf-1.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-sm-2 col-xs-12 links-tab">
              <div className="widget">
                <div className="pages">
                  <p></p>
                  <ul>
                    <li>
                      <a
                        href="https://liontree.com/our-platform/"
                        target="_blank"
                      >
                        Our Platform
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://liontree.com/our-leadership/"
                        target="_blank"
                      >
                        Our Leadership{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://liontree.com/experience/"
                        target="_blank"
                      >
                        {" "}
                        Our Experience{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://liontree.com/news-perspectives/"
                        target="_blank"
                      >
                        News &amp; Perspectives
                      </a>
                    </li>
                    <li>
                      <a href="https://liontree.com/contact/" target="_blank">
                        Contact{" "}
                      </a>
                    </li>
                    <li className="social-icons">
                      <a
                        href="https://www.linkedin.com/company/liontree/"
                        target="_blank"
                      >
                        <img src={IcLinked} alt="LinkedIn" />
                      </a>
                      <a
                        href="https://www.instagram.com/liontree_official/"
                        target="_blank"
                      >
                        <img src={IcInsta} alt="Instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-xs-12 links-tab">
              <div className="widget">
                <div className="pages">
                  <p></p>
                  <ul>
                    <li>
                      <a
                        href="https://liontree.com/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="https://liontree.com/form-crs/" target="_blank">
                        Form CRS
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://liontree.com/disclaimer/"
                        target="_blank"
                      >
                        Disclaimers
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-2 col-xs-12 links-tab">
              <div className="widget">
                <div className="pages">
                  <p></p>
                  <ul className="ulfooter">
                    <li>New York</li>
                    <li>San Francisco</li>
                    <li>London</li>
                    <li>Paris</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- <div className="row text-center">
                    <div className="col-sm-12">
                        <div className="Legal">
							<p> </p>
                        </div>
                    </div>
                </div> --> */}
        </div>
      </footer>

      <footer className="footer d-none">
        <div className="container">
          <div className="footer__brand">
            <LogoTwo />
          </div>
          <div className="footer__row">
            <div className="footer__left">
              <ul className="footer__info">
                <li>
                  <h3 className="footer__info--title">Media Inquiries</h3>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Mail to communications@liontree.com",
                        "mailto:communications@liontree.com"
                      )
                    }
                    href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    communications@liontree.com
                  </a>
                </li>
                <li>
                  <h3 className="footer__info--title">Join Our Team</h3>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Mail to careers@liontree.com",
                        "mailto:careers@liontree.com"
                      )
                    }
                    href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    careers@liontree.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__right">
              <h3 className="footer__info--title">Our Offices</h3>
              <ul className="footer__offices">
                <li>
                  <h4 className="footer__info--subtitle">NEW YORK</h4>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Map show for 745 Fifth Avenue, New York, NY 10151",
                        "https://www.google.com/maps?q=745+Fifth+Avenue+New+York+10151"
                      )
                    }
                    href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    745 Fifth Avenue, <br /> New York, NY 10151
                  </a>
                </li>
                <li>
                  <h4 className="footer__info--subtitle">London</h4>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Map show for 3 Burlington Gardens, London W1S 3EP",
                        "https://www.google.com/maps?q=3+Burlington+Gardens+London+W1S+3EP"
                      )
                    }
                    href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3 Burlington Gardens, <br /> London W1S 3EP
                  </a>
                </li>
                <li>
                  <h4 className="footer__info--subtitle">San francisco</h4>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Map show for One Market Plaza, Spear Tower, San Francisco, CA 94105",
                        "https://www.google.com/maps?q=One+Market+Plaza+Spear+Tower+San+Francisco+94105"
                      )
                    }
                    href="javascript:void(0);"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    One Market Plaza, Spear Tower, <br /> San Francisco, CA
                    94105
                  </a>
                </li>
                <li>
                  <h4 className="footer__info--subtitle">Paris</h4>
                  <a
                    onClick={() =>
                      handledClicked(
                        "Map show for 7 Rue Rouget de L'Isle, Paris 75001",
                        "https://www.google.com/maps?q=7+Rue+Rouget+de+L%E2%80%99Isle+Paris+75001"
                      )
                    }
                    href="javascript:void(0)"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    7 Rue Rouget de L'Isle, <br /> Paris 75001
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__copyrights">
              © LionTree LLC, {year}. All rights reserved.
            </div>
            <ul className="footer__bottom--links">
              <li>
                <a
                  onClick={() =>
                    handledClicked(
                      "Privacy Policy",
                      `${process.env.REACT_APP_API_HOSTNAME}${resourceUrl}`
                    )
                  }
                  href="javascript:void(0)"
                >
                  Privacy Policy
                </a>
              </li>
              {/*<li>
                <a
                  onClick={() =>
                    handledClicked(
                      "Form CRS",
                    `${process.env.REACT_APP_API_HOSTNAME}/-/media/Project/LionTree/NewLandingPage/Form-CRS/LionTree_Advisors_LLC_-_Form_CRS.ashx`
                    )
                  }
                  href="javascript:void(0)"
                >
                  Form CRS
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                >
                  Disclaimers
                </a>
              </li>*/}
            </ul>
          </div>
        </div>
      </footer>
      <CookieConsentCall
        isCookieConsent={props.isCookieConsent}
        setIsCookieConsent={props.setIsCookieConsent}
        handledClicked={handledClicked}
        resourceUrl={resourceUrl}
      />
    </>
  );
}
