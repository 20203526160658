import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pdf } from "../../components/icons";
import moment from "moment";
import { get, orderBy } from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.scss";
import { browserName } from "react-device-detect";
import { getDevice } from "../../constants/constant";

const HOST_URL = process.env.REACT_APP_API_HOSTNAME;
const IMAGE_BLOB_URL = process.env.REACT_APP_IMAGE_BLOB_URL;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

export default function FeedComponent(props) {
  const [weeklyData, setWeeklyData] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isPosition, setIsPostionSet] = useState(false);
  const [isAnalyticSave, setIsAnalyticSave] = useState(false);

  const navigate = useNavigate();

  const scrollPosition = (s) => {
    window.scrollTo(0, s);
  };

  const loadMore = async () => {
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "00000000-0000-0000-0000-000000000000",
      NewsletterDate: "",
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: "Load More Click",
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle:"Load More Click"
    };
    props.SavedLTWebsiteAnalytics(obj);
    setIsLoading(true);
    let newNumber = get(props, "totalPageNumber", 0) + 1;
    await props.getLtweeklyData({
      pageNumber: newNumber,
      limit: limit,
    });
    setPageNumber(newNumber);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    props.updateCurrentPosition({ currentPosition: position });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pageViewLink = (actionDescription) => {
    // let itemDate = window.location.pathname.split("/")[2];
    // let itemId = window.location.pathname.split("/")[3];
    // itemId = itemId ? itemId : "";
    // itemDate =
    //   itemDate &&
    //   itemDate !== "archives" &&
    //   itemDate !== "about" &&
    //   itemDate !== undefined
    //     ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
    //     : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "pageview",
      NewsletterID: "00000000-0000-0000-0000-000000000000",
      NewsletterDate: "",
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle: actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  const handledClicked = (actionDescription, val) => {
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "00000000-0000-0000-0000-000000000000",
      NewsletterDate: "",
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const getLongDescription = (str, data) => {
    str = str.substr(0, 500);
    if (str.includes("<a")) {
      return str
        .substr(0, 375)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false">read more</a>`
        )
        .replace("", "");
    } else {
      return str
        .substr(0, 200)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false">read more</a>`
        )
        .replace("", "");
    }
  };

  const linkClicked = (actionDescription, url, mainId,itemName="") => {
    let ArticleTitle = "";
    actionDescription = getThemeTitle(actionDescription);
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Read more" &&
      actionDescription !== "Intro"
    ) {
      ArticleTitle = actionDescription;
      actionDescription = "Theme Title - " + actionDescription;
    }
    let itemDate = url.split("/")[2];
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "Key News by Sub-Sector" &&
      actionDescription !== "Read more" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "clicked",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle: ArticleTitle?ArticleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      if (!isAnalyticSave) {
        setIsAnalyticSave(true);
        pageViewLink("Home Page");
      }
      navigate("/LTWeekly");
      setLimit(10);
      const fetchData = async () => {
        await props.getLtweeklyData({
          pageNumber: props.totalPageNumber,
          limit: limit,
        });
      };
      if (props.totalLtweeklyData.length > 0) {
        setWeeklyData(props.totalLtweeklyData);
      } else {
        fetchData();
        setIsMounted(true);
      }
    }
    if (!isPosition) {
      let timeOff = setInterval(() => {
        scrollPosition(get(props, "currentPosition", 0));
        clearInterval(timeOff);
      }, 400);
      setIsPostionSet(true);
    }
    if (get(props, "ltweeklyPhase", "") === "success") {
      props.initHomePhase();
      if (props.totalLtweeklyData.length > 0) {
        setWeeklyData(props.totalLtweeklyData);
      } else {
        const concateData = [...weeklyData, ...get(props, "ltweeklyData", [])];
        setWeeklyData(concateData);
      }
      setIsLoading(false);
      setTotalRecords(get(props, "ltweeklyTotalRecords", ""));
    }
  }, [weeklyData, isMounted, limit, pageNumber, props]);

  const getThemeTitle = (title) => {
    if (title.includes("<span")) {
      title = title.split("<span>")[1];
      title = title.split("</span>")[0];
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          if (title.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const contentClickHandler = (data) => {
    navigate(
      `/LTWeekly/${moment(get(data, "ItemName", ""), "MM DD YYYY").format(
        "MMMM-DD-YYYY"
      )}/${get(data, "ItemID", "")}`
      // { state: { data: data, isFrom: "feed" } }
    );
    // window.location.reload(true);
  };
  
  return (
    <>
      {!(weeklyData.length > 0) ? (
        <>
          <h1 style={{ marginTop: 200 }}> </h1>
          <Spin
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 30, color: "#000" }} spin />
            }
            className="loader__full"
          />
        </>
      ) : (
        <div className="feed__container">
          <div className="feed__row">
            {weeklyData.length > 0 &&
              weeklyData.map((data, i) => {
                data.ChildList = orderBy(
                  data.ChildList,
                  (item) => parseInt(item.ItemName),
                  ["asc"]
                );
                return (
                  <div key={i} className="feed__item">
                    <div className="feed__header">
                      <h1 className="feed__title">
                        LT Weekly:{" "}
                        {moment(get(data, "ItemName", ""), "MM DD YYYY").format(
                          "MMM D, YYYY"
                        )}
                      </h1>
                      {/* 
                         `${HOST_URL}/api/utility/pdf?path=%2fsitecore%2fcontent%2fKindredApp3%2fLT+Weekly%2f${data.ItemName}`,
                      */}
                      <a
                        onClick={() =>
                          handledClicked(
                            "Download Pdf",
                            `${HOST_URL}/Dashboard/pdf?WeeklyDate=${data.ItemName}`
                          )
                        }
                        href="javascript:void(0)"
                        className="feed__download"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Pdf />
                      </a>
                    </div>
                    <Link
                      onClick={() => {
                        linkClicked(
                          "Intro",
                          `/LTWeekly/${moment(
                            get(data, "ItemName", ""),
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${get(data, "ItemID", "")}`,
                          get(data, "ItemID", "")
                        );
                        navigate(
                          `/LTWeekly/${moment(
                            get(data, "ItemName", ""),
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${get(data, "ItemID", "")}`
                          // { state: { data: data, from: "feed" } }
                        );
                        window.location.reload(true);
                      }}
                      to={`#`}
                      // state={{ data, isFrom: "feed" }}
                      className="feed__thumb"
                    >
                      <img
                        src={
                          get(data, "Photo", "")?.includes(IMAGE_BLOB_URL)
                            ? get(data, "Photo", "")?.replaceAll(HOST_URL, "")
                            : get(data, "Photo", "")
                        }
                        alt=""
                      />
                    </Link>
                    <div className="feed__desc">
                      <div className="read-more-content">
                        <div
                          onClick={() => {
                            linkClicked(
                              "Read more",
                              `/LTWeekly/${moment(
                                get(data, "ItemName", ""),
                                "MM DD YYYY"
                              ).format("MMMM-DD-YYYY")}/${get(
                                data,
                                "ItemID",
                                ""
                              )}`,
                              get(data, "ItemID", "")
                            );
                            contentClickHandler(data);
                          }}
                          className="feed__editor"
                          dangerouslySetInnerHTML={{
                            __html: getLongDescription(
                              data.LongDescription,
                              data
                            ),
                          }}
                        ></div>
                      </div>
                      <ul className="feed__desc--links">
                        <li key={get(data, "ItemID", "") + 0}>
                          <Link
                            onClick={() => {
                              linkClicked(
                                "Intro",
                                `/LTWeekly/${moment(
                                  get(data, "ItemName", ""),
                                  "MM DD YYYY"
                                ).format("MMMM-DD-YYYY")}/${get(
                                  data,
                                  "ItemID",
                                  ""
                                )}`,
                                get(data, "ItemID", "")
                              );
                              navigate(
                                `/LTWeekly/${moment(
                                  get(data, "ItemName", ""),
                                  "MM DD YYYY"
                                ).format("MMMM-DD-YYYY")}/${get(
                                  data,
                                  "ItemID",
                                  ""
                                )}`
                                // { state: { data: data, from: "feed" } }
                              );
                              window.location.reload(true);
                            }}
                            to={`#`}
                            // state={{ data, isFrom: "feed" }}
                          >
                            Intro
                          </Link>
                          <ol className="feed__desc--links-child">
                            {get(data, "ChildList", []).length > 0 &&
                              get(data, "ChildList", []).map((item, j) => {
                                return (
                                  <li key={j}>
                                    <Link
                                      onClick={() =>
                                        linkClicked(
                                          get(item, "ShortDescription", ""),
                                          `/LTWeekly/${moment(
                                            get(data, "ItemName", ""),
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            item,
                                            "ItemID",
                                            ""
                                          )}`,
                                          get(data, "ItemID", ""),
                                          get(item, "ItemName","")
                                        )
                                      }
                                      to={`/LTWeekly/${moment(
                                        get(data, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemID",
                                        ""
                                      )}`}
                                      //  state={{ data, isFrom: "feed" }}
                                    >
                                      {getThemeTitle(
                                        get(item, "ShortDescription", "")
                                      )}
                                    </Link>
                                  </li>
                                );
                              })}
                          </ol>
                        </li>
                        {get(data, "Stock", "") !== "" &&
                          get(data, "Stock", "") !== null && (
                            <li key={get(data, "ItemID", "") + 1}>
                              <Link
                                onClick={() =>
                                  linkClicked(
                                    "Stock Market Check",
                                    `/LTWeekly/${moment(
                                      get(data, "ItemName", ""),
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      data,
                                      "ItemID",
                                      ""
                                    )}/stock-check`,
                                    get(data, "ItemID", "")
                                  )
                                }
                                to={`/LTWeekly/${moment(
                                  get(data, "ItemName", ""),
                                  "MM DD YYYY"
                                ).format("MMMM-DD-YYYY")}/${get(
                                  data,
                                  "ItemID",
                                  ""
                                )}/stock-check`}
                                // state={{ data, isFrom: "feed" }}
                              >
                                Stock Market Check
                              </Link>
                            </li>
                          )}
                        {get(data, "OtherNews", []).length > 0 && (
                          <li key={get(data, "ItemId", "") + 2}>
                            <Link
                              onClick={() =>
                                linkClicked(
                                  "Key News by Sub-Sector",
                                  `/LTWeekly/${moment(
                                    get(data, "ItemName", ""),
                                    "MM DD YYYY"
                                  ).format("MMMM-DD-YYYY")}/${get(
                                    data,
                                    "ItemID",
                                    ""
                                  )}/sub-sector`,
                                  get(data, "ItemID", "")
                                )
                              }
                              to={`/LTWeekly/${moment(
                                get(data, "ItemName", ""),
                                "MM DD YYYY"
                              ).format("MMMM-DD-YYYY")}/${get(
                                data,
                                "ItemID",
                                ""
                              )}/sub-sector`}
                              // state={{ data, isFrom: "feed" }}
                            >
                              Key News by Sub-Sector
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="feed__footer">
            {!(totalRecords === weeklyData.length) && (
              <button
                onClick={() => loadMore()}
                className="btn btn-lg btn__purple"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "load more"}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
