import React, { useCallback, useEffect, useState } from "react";
import { Spin } from "antd";
import { get, isEmpty } from "lodash";
import moment from "moment";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { browserName } from "react-device-detect";
import { getDevice } from "../../constants/constant";
import { LoadingOutlined } from "@ant-design/icons";
import OtherNewsDefault from "../../images/other_news_default.png";
import ThemesImage from "../../images/Weekly_Update_v2.jpg";

let cancelToken;
let cancelTimeout;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);

const Search = ({
  props,
  activeTab,
  ltWeeklyItemData,
  SavedLTWebsiteAnalytics,
  SearchThemesAndOtherNews,
}) => {
  const [open, setOpen] = useState(false);
  const [subTab, setSubTab] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [news, setOtherNews] = useState([]);
  const [themes, setThemes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [isSearchTextEmpty, setIsSearchTextEmpty] = useState(true);
  const location = useLocation();
  const [isMount, setIsMount] = useState(false);
  const [querySearchTextData, setQuerySearchTextData] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isloadMore, setIsLoadMore] = useState(false);

  const [searchResultTotalCount, setSearchResultTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [newsThemesCount, setNewsThemesCount] = useState(0);
  const Navigate = useNavigate();

  const getThemeTitle = (title) => {
    if (title?.includes("<span")) {
      title = title?.split("<span>")[1];
      title = title?.split("</span>")[0];
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title?.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title?.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          if (title?.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title?.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title?.includes("<a")) {
      if (title?.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title?.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title?.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title?.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title?.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const handledClicked = (actionDescription, val) => {
    let email = localStorage.getItem("email");
    let itemDate = window.location.pathname.split("/")[2];
    let itemId = window.location.pathname.split("/")[3];
    itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(itemDate, "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    // "June 2, 2022 at 1:30pm EST"
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: val.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: get(ltWeeklyItemData, "ItemId", ""),
      NewsletterDate: itemDate,
      ThemeID: "",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
    };
    SavedLTWebsiteAnalytics(obj);
    window.open(val, "_blank");
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const callSearchApi = async (e = "", data = {}) => {
    let searchValue;
    if (isEmpty(e) || e?.target?.value === "") {
      setThemes([]);
      setOtherNews([]);
      setNewsThemesCount(0);
      setSearchResultTotalCount(0);
      setIsSearchTextEmpty(true);
      setShowNoDataMessage(false);
      return;
    }
    const queryParams = location.search;
    const querySearchText = queryParams.replace("?searchText=", "") || "";
    if (!isEmpty(e)) {
      setLoader(true);
      searchValue = e?.target?.value ? e?.target?.value : e;
      setIsSearchTextEmpty(searchValue === "");
      setPage(1);
    }
    // setLoader(true)

    let payload = {};

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    // save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    if (!isEmpty(e) && e?.target?.value !== "") {
      payload = {
        Text: e.target?.value ? e.target?.value : e,
        token: cancelToken.token,
        Page: page,
        Count: 100,
      };
    } else {
      payload = {
        Text: "",
        token: cancelToken.token,
        Page: page,
        Count: 100,
      };
    }
    clearInterval(cancelTimeout);
    setShowMessage(false);
    const { value } = await SearchThemesAndOtherNews(payload);
    if (typeof cancelTimeout !== "undefined") {
      // clearTimeout(cancelTimeout);
    }
    if (value?.Success) {
      setSearchResultTotalCount(value?.TotalCount);
      let newsthemcount = newsThemesCount + value?.Result?.length;
      setNewsThemesCount(newsthemcount);
      const themes = value?.Result?.filter((item) => item.Type === 0);
      const news = value?.Result?.filter((item) => item.Type === 2);
      setIsSearchTextEmpty(false);
      setOtherNews(news);
      setThemes(themes);
      if (!isEmpty(searchValue)) {
        cancelTimeout = setInterval(() => {
          // setShowNoDataMessage(value?.Result?.News.length === 0 && value?.Result?.Themes.length === 0);
          // setShowNoDataMessage(value?.TotalCount === 0 || (value?.Result?.length === 0|| value?.Result === null));
          // setShowMessage(true)
          setShowNoDataMessage(false);
          setLoader(false);
          clearInterval(cancelTimeout);
        }, 250); // Adjust the delay time (in milliseconds) as per your requirement
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      setSearchResultTotalCount(value?.TotalCount);
      if (!isEmpty(searchValue)) {
        cancelTimeout = setInterval(() => {
          // setShowNoDataMessage(value?.Result?.News.length === 0 && value?.Result?.Themes.length === 0);
          setShowNoDataMessage(
            value?.TotalCount === 0 ||
              value?.Result?.length === 0 ||
              value?.Result === null
          );
          setIsSearchTextEmpty(false);
          setShowMessage(true);
          setLoader(false);
          clearInterval(cancelTimeout);
        }, 250);
      }
      setOtherNews([]);
      setThemes([]);
      setNewsThemesCount(0);
    } // Hide loader after API response is processed
    setShowMessage(false);
  };

  const optimizedUserFn = useCallback(debounce(callSearchApi), []);

  const imageOk = async (image, imageTag) => {
    let oImg = new Image();
    oImg.src = image;
    let status = false;
    oImg.onload = async () => {
      // return true;
      status = true;
      return status;
    };
    oImg.onerror = async () => {
      status = false;

      let image = document.querySelector(imageTag);
      if (image) {
        image.src = OtherNewsDefault;
      }
      return status;
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      const queryParams = location.search;
      if (!isMount) {
        setIsMount(true);
        const querySearchText = queryParams.replace("?searchText=", "") || "";

        setQuerySearchTextData(querySearchText);
        await optimizedUserFn(querySearchText);
      }

      let { pathname } = location;
      if (pathname === "/LTWeekly") {
        // headerAnalytics('Home Page')
      } else if (pathname.includes("/LTWeekly/archives/")) {
        // headerAnalytics('Archive Page')
      } else if (pathname === "/LTWeekly/about") {
        // headerAnalytics('About')
      }
    };

    fetchData();
  }, [location.pathname, location.search, optimizedUserFn]);

  const loadMore = async () => {
    setIsLoadMore(true);
    let pageNumber = page + 1;
    let payload = {};
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    payload = {
      Text: searchText ? searchText : querySearchTextData,
      token: cancelToken.token,
      Page: pageNumber,
      Count: 100,
    };
    const { value } = await SearchThemesAndOtherNews(payload);
    if (value?.Success) {
      let newsthemcount = newsThemesCount + value?.Result?.length;
      setNewsThemesCount(newsthemcount);
      setSearchResultTotalCount(value?.TotalCount);
      const updatedthemes = value?.Result?.filter((item) => item.Type === 0);
      const updatednews = value?.Result?.filter((item) => item.Type === 2);
      setOtherNews([...news, ...updatednews]);
      setThemes([...themes, ...updatedthemes]);
      setPage(pageNumber);
      setIsLoadMore(false);
    } else {
      setOtherNews([]);
      setThemes([]);
      setNewsThemesCount(0);
      setPage(1);
      setIsLoadMore(false);
    }
  };

  const getHrefUrl = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const linkElement = doc.querySelector("a");
    const hrefValue = linkElement?.getAttribute("href");
    return hrefValue;
  };

  const redirectToLink = (link) => {
    window.open(link, "_blank");
  };

  const getExternalUrl = (externalUrl) => {
    window.open(externalUrl, "_blank");
  };

  useEffect(() => {}, [subTab, activeTab]);

  return (
    <>
      <div className="container">
        <div className="search-page">
          <div className="form-group mt-2">
            <input
              placeholder="Search"
              className="form-control search-input"
              value={searchText}
              type="text"
              name="searchText"
              onChange={(e) => {
                setSearchText(e.target.value);
                setQuerySearchTextData("");
                optimizedUserFn(e);
              }}
            />
          </div>
          {(searchText || querySearchTextData) && (
            <p>
              Results for: "
              <b>
                {searchText
                  ? searchText.replace("%20", "")
                  : querySearchTextData.replaceAll("%20", " ")}
              </b>
              "
            </p>
          )}
          {loader ? (
            <Spin
              size="small"
              // className="loader__small"
              className="loader__full"
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 30, color: "#000", textAlign: "center" }}
                  spin
                />
              }
            />
          ) : (
            <div className="sidebarSearch mt-3">
              <div className="searchItem">
                {themes &&
                  themes?.map((t) => {
                    return (
                      <div
                        className="itemList itemList-bg"
                        // onClick={() => redirectToLink(getHrefUrl(t.ShortDescription))}
                      >
                        <div
                          className="itemImg"
                          onClick={() =>
                            // redirectToLink(getHrefUrl(t.ShortDescription))
                            // window.open(
                            //   `${
                            //     process.env.REACT_APP_SITE_URL +
                            //     "/LTWeekly/" +
                            //     moment(new Date(t.ItemName)).format(
                            //       "MMM-DD-yyyy"
                            //     ) +
                            //     "/" +
                            //     t.Id
                            //   }`,
                            //   "_blank"
                            // )
                            window.open(
                              `/LTWeekly/${moment(new Date(t.ItemName)).format(
                                "MMMM-DD-yyyy"
                              )}/${t.Id}`,
                              "_blank"
                            )
                          }
                        >
                          <img
                            src={
                              t.Photo?.includes("westus2storpublic")
                                ? t.Photo
                                : t.Photo
                                ? process.env.REACT_APP_API_HOSTNAME + t.Photo
                                : ThemesImage
                            }
                          />
                        </div>
                        <div className="itemDetail">
                          <div className="itemTitle">
                            {t.ThemeIndex}.{" "}
                            <a
                              // href={`${
                              //   process.env.REACT_APP_SITE_URL +
                              //   "/LTWeekly/" +
                              //   moment(new Date(t.ItemName)).format(
                              //     "MMM-DD-yyyy"
                              //   ) +
                              //   "/" +
                              //   t.Id
                              // }`}
                              href={`/LTWeekly/${moment(
                                new Date(t.ItemName)
                              ).format("MMMM-DD-yyyy")}/${t.Id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="itemTitle"
                              style={{ textDecoration: "underline" }}
                            >
                              {t.ShortDescription?.includes("<a") ? (
                                getThemeTitle(t.ShortDescription)
                              ) : (
                                <>
                                  {t.ShortDescription.replaceAll(
                                    "<span>",
                                    ""
                                  ).replaceAll("</span>", "")}
                                </>
                              )}
                            </a>
                          </div>
                          <p className="itemPara">
                            LT Weekly:{" "}
                            {moment(t.ItemName, "MM-DD-YYYY").format(
                              "DD MMM, YYYY"
                            )}
                          </p>
                          <div className="d-flex flex-wrap">
                            {t.Category !== "" &&
                              t.Category !== null &&
                              t.Category?.split("|").map((s, index) => {
                                return (
                                  <React.Fragment>
                                    <p className="tag-name me-2 mb-1">{s} </p>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {news &&
                  news?.map((n, i) => {
                    return (
                      <div
                        className="itemList"
                        // onClick={() => getExternalUrl(n.ExtUrl)}
                        key={n.ExtUrl}
                      >
                        <div
                          className="itemImg"
                          onClick={() => getExternalUrl(n.ExtUrl)}
                        >
                          <img
                            style={{
                              backgroundImage: imageOk(
                                n.Photo?.replaceAll("&amp;", "&"),
                                `.image${i}`
                              )
                                ? ""
                                : `url(${OtherNewsDefault})`,
                              backgroundSize: `cover`,
                              backgroundPosition: `center`,
                            }}
                            className={`image${i}`}
                            src={
                              n.Photo &&
                              n.Photo !== "" &&
                              imageOk(
                                n.Photo?.replaceAll("&amp;", "&"),
                                `.image${i}`
                              )
                                ? n.Photo?.replaceAll("&amp;", "&")
                                : OtherNewsDefault
                            }
                            alt=""
                          />
                        </div>
                        <div className="itemDetail">
                          <a
                            href={n.ExtUrl}
                            style={{ textDecoration: "underline" }}
                            className="itemTitle"
                            target="_blank"
                          >
                            {n.ShortDescription}
                          </a>
                          <p className="itemPara">
                            {" "}
                            Other News | {n.Category?.replaceAll("|", " | ")}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {(searchText || querySearchTextData) &&
                  (news.length > 0 || themes.length > 0) &&
                  searchResultTotalCount !== newsThemesCount && (
                    <button
                      onClick={() => loadMore()}
                      className="btn btn-lg btn__purple loadmore-list"
                    >
                      {isloadMore ? <Spin indicator={antIcon} /> : "load more"}
                    </button>
                  )}
                {(searchText !== "" || querySearchTextData !== "") &&
                  showNoDataMessage &&
                  !loader &&
                  showMessage && (
                    <div className="mb300">
                      <div className="d-block resultes-for">
                        We didn’t find any matches for{" "}
                        <b className="boldtext">"{searchText}"</b>. Please try
                        to search for something else.
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Search;
