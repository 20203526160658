import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header/container";
import Footer from "../../components/common/Footer/container";
import Announcement from "../../components/common/Announcement/container";
import NewAnnouncement from "../../components/common/NewAnnouncement/container";
import Feed from "../Feed/container";
import NewHome from "../NewHome/container";
import Archives from "../Archives/container";
import About from "../About/container";
import Search from "../Search/container";
import { get } from "lodash";
import "./styles.scss";
import News from "../News/component";
const REDIRECT_URL = "/iimform";

export default function HomeComponent(props) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();

  const onTabChange = (value) => {
    if (value === "home") {
      setActiveTab(value);
      navigate("/LTWeekly", { state: {} });
    } else if (value === "archives") {
      setActiveTab(value);
      navigate("/LTWeekly/archives/2024", { state: {} });
    } else if (value === "about") {
      setActiveTab(value);
      navigate("/LTWeekly/about", { state: {} });
    } else if (value === "news") {
      setActiveTab(value);
      navigate("/LTWeekly/bysub-sector", { state: {} });
    }
  };

  const [categoryCount, setCategoryCount] = useState([]);
  const [tagsCount, setTagsCount] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [isCookieConsent, setIsCookieConsent] = useState(false);
  const { pathname, search } = location;

  useEffect(() => {
    if (!isMounted) {
      const fetchData = async () => {
        const { value } = await props.GetCategoriesAndCount({
          assignedDate: "",
        });
        setCategoryCount(get(value, "CatResults", []));
      };
      fetchData();
      const fetchThemesCategoriesData = async () => {
        const { value } = await props.GetArticalCategoriesCount({
          assignedDate: "",
        });
        setTagsCount(value);
      };
      fetchThemesCategoriesData();
    }
    // let userIsExists = localStorage.getItem("email");
    // if (!userIsExists) {
    //   window.location.href = REDIRECT_URL;
    // }

    if (pathname === "/LTWeekly") {
      setActiveTab("home");
      navigate("/LTWeekly", { state: {} });
    } else if (pathname.includes("/LTWeekly/archives/")) {
      setActiveTab("archives");
      navigate(pathname, { state: {} });
    } else if (pathname === "/LTWeekly/about") {
      setActiveTab("about");
      navigate("/LTWeekly/about", { state: {} });
    } else if (pathname === "/LTWeekly/bysub-sector") {
      setActiveTab("news");
      navigate("/LTWeekly/bysub-sector" + search, { state: {} });
    } else if (pathname === "/new-home") {
      setActiveTab("home");
      navigate("/new-home", { state: {} });
    } else if (pathname === "/old-home") {
      setActiveTab("home");
      navigate("/old-home", { state: {} });
    } else if (pathname.includes("/LTWeekly/search")) {
      setActiveTab("search-news");
      navigate(pathname + search, { state: {} });
    }
  }, [activeTab, navigate, isMounted]);

  return (
    <>
      <Header
        {...props}
        onTabChange={onTabChange}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
        SavedLTWebsiteAnalytics={props.SavedLTWebsiteAnalytics}
        CheckUserAuthenticity={props.CheckUserAuthenticity}
      />

      <main className="main">
        {activeTab === "home" && (
          <>
            {pathname !== "/old-home" ? (
              <NewAnnouncement {...props} />
            ) : (
              <Announcement {...props} />
            )}
            {pathname !== "/old-home" ? (
              <NewHome
                {...props}
                onTabChange={onTabChange}
                setActiveTab={setActiveTab}
                SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
                activeTab={activeTab}
                getLatestLTWeeklyNews={props.getLatestLTWeeklyNews}
              />
            ) : (
              <Feed
                {...props}
                onTabChange={onTabChange}
                setActiveTab={setActiveTab}
                SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
                activeTab={activeTab}
              />
            )}
          </>
        )}
        {activeTab === "archives" && (
          <>
            <Archives
              {...props}
              onTabChange={onTabChange}
              setActiveTab={setActiveTab}
              SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
              activeTab={activeTab}
            />
          </>
        )}
        {activeTab === "news" && (
          <>
            <News
              {...props}
              categoryCount={categoryCount}
              setCategoryCount={setCategoryCount}
              SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
              SavedLTWebsiteAnalytics={props.SavedLTWebsiteAnalytics}
              tagsCount={tagsCount}
              getLTWeeklyThemesCategories={props.getLTWeeklyThemesCategories}
              getLTWeeklyAndOthersNewsCategories={
                props.getLTWeeklyAndOthersNewsCategories
              }
              CheckUserAuthenticity={props.CheckUserAuthenticity}
            />
          </>
        )}
        {activeTab === "about" && (
          <>
            <About
              {...props}
              SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
            />
          </>
        )}
        {activeTab === "search-news" && (
          <Search
            {...props}
            SearchThemesAndOtherNews={props.SearchThemesAndOtherNews}
            SavedLTWebsiteAnalytics={props.SavedLTWebsiteAnalytics}
          />
        )}
      </main>

      <Footer
        isCookieConsent={isCookieConsent}
        setIsCookieConsent={setIsCookieConsent}
        {...props}
      />

      <div className={!isCookieConsent ? "overlay-restrict" : ""}></div>
    </>
  );
}
