import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

function CookieConsentCall({
  isCookieConsent,
  setIsCookieConsent,
  resourceUrl,
  handledClicked,
}) {
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const userConsent = localStorage.getItem("userConsent");
    if (userConsent === "accepted") {
      setIsCookieConsent(true);
      setShowCookieBanner(false);
    }
  }, []);
  const handleCookieAccept = () => {
    const { search } = location;
    let userData = {};

    // Handle user's acceptance of cookies and remember it.
    localStorage.setItem("userConsent", "accepted");
    if (!isEmpty(search)) {
      localStorage.setItem(
        "t",
        search.replace("?token=", "")?.replace("?t=", "")?.replace("&email=true","")
      );
    }
    setIsCookieConsent(true);
    setShowCookieBanner(false);
  };
  return (
    <>
      {showCookieBanner && (
        <div className="footer-message">
          <CookieConsent
            location="bottom"
            buttonText="Continue"
            cookieName="myAwesomeCookieName2"
            expires={150}
            onAccept={handleCookieAccept}
          >
            Our website and third-party tools use cookies and similar
            technologies to analyze site usage. Learn more in our{" "}
            <a
              onClick={() =>
                handledClicked(
                  "Privacy Policy",
                  `${resourceUrl}`
                )
              }
            >
              Privacy Policy
            </a>
            .
          </CookieConsent>
        </div>
      )}{" "}
    </>
  );
}
export default CookieConsentCall;
