import { connect } from 'react-redux'
import { GetCategoriesAndCount, getOtherNewsByCategories, SearchThemesAndOtherNews, SavedLTWebsiteAnalytics, initPhase, getResources, initHomePhase,getAllLTWeeklyDates,GetArticalCategoriesCount,getLTWeeklyThemesCategories,getLTWeeklyAndOthersNewsCategories,getLatestLTWeeklyNews,sendPollAnswer,CheckUserAuthenticity } from '../../store/home/duck'

import HomeComponent from './component'

const HomeContainer = connect(
  // Map state to props
  state => ({
    GetCategoriesCountData: state.home.GetCategoriesCountData,
    GetCategoriesCountPhase: state.home.GetCategoriesCountPhase
  }),
  // Map actions to dispatch and props
  {
    SearchThemesAndOtherNews,
    getOtherNewsByCategories,
    GetCategoriesAndCount,
    SavedLTWebsiteAnalytics,
    getResources, 
    initHomePhase,
    initPhase,
    getAllLTWeeklyDates,
    GetArticalCategoriesCount,
    getLTWeeklyThemesCategories,
    getLTWeeklyAndOthersNewsCategories,
    getLatestLTWeeklyNews,
    sendPollAnswer,
    CheckUserAuthenticity
  }
)(HomeComponent)

export default HomeContainer
