import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics, SearchThemesAndOtherNews } from '../../store/home/duck'
import Search from './component'

const SearchContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    SearchThemesAndOtherNews
  }
)(Search)

export default SearchContainer