import React, { useState, useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// components
import SubscriptionCenter from "../views/SubscriptionCenter/container";
import Home from "../views/Home/container";
import HomeNew from "../views/NewHome/Component";
import WeeklyDetail from "../views/WeeklyDetail/container";
import FeedComponentFee from "../views/Feed/container";
import Disclaimers from "../views/Disclaimers/container";
import UnsubscribeUser from "../views/UnsubscribeUser/container";
function App(props) {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/LTWeekly" />} />
        <Route exact path="/iimform" element={<SubscriptionCenter />} />
        <Route exact path="/LTWeekly/disclaimers" element={<Disclaimers />} />
        {/*       <Route exact path='/LTWeekly' element={<Home {...props} />} />
         */}{" "}
        <Route exact path="/LTWeekly" element={<Home {...props} />} />
        <Route
          exact
          path="/unsubscribeuser/:email"
          element={<UnsubscribeUser {...props} />}
        />
        <Route exact path="/old-home" element={<Home {...props} />} />
        <Route
          exact
          path="/LTWeekly/archives/:year"
          element={<Home {...props} />}
        />
        <Route exact path="/LTWeekly/about" element={<Home {...props} />} />
        <Route
          exact
          path="/LTWeekly/bysub-sector"
          element={<Home {...props} />}
        />
        <Route exact path="/LTWeekly/search" element={<Home {...props} />} />
        <Route path="/weekly-detail" element={<WeeklyDetail {...props} />} />
        <Route
          path="/LTWeekly/:date/:id/:itemId"
          element={<WeeklyDetail {...props} />}
        />
        <Route
          path="/LTWeekly/:date/:itemId"
          element={<WeeklyDetail {...props} />}
        />
        <Route path="/feed" element={<FeedComponentFee {...props} />} />
      </Routes>
    </Router>
  );
}

export default App;
