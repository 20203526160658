import * as React from "react"

const ListView = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
      { ...props }
    >
      <path
        fill="currentColor"
        d="M3.66 1.413h14.75a.706.706 0 100-1.413H3.66a.707.707 0 100 1.413zM18.41 4.398H3.66a.707.707 0 100 1.414h14.75a.706.706 0 100-1.414zM18.41 8.794H3.66a.706.706 0 100 1.413h14.75a.706.706 0 100-1.413zM18.41 13.188H3.66a.706.706 0 100 1.414h14.75a.706.706 0 100-1.414zM18.41 17.587H3.66a.706.706 0 100 1.413h14.75a.706.706 0 100-1.413zM1.137.12a.807.807 0 00-.123-.067.73.73 0 00-.54 0 .792.792 0 00-.227.155.707.707 0 10.996.997A.706.706 0 001.137.12zM1.399 4.832a.851.851 0 00-.067-.123.618.618 0 00-.089-.106.727.727 0 00-.996 0 .513.513 0 00-.088.106.852.852 0 00-.067.123.893.893 0 00-.04.13.623.623 0 000 .142.706.706 0 00.98.65.652.652 0 00.3-.25.5.5 0 00.067-.12.56.56 0 00.053-.124.61.61 0 000-.138.736.736 0 00-.053-.272v-.018zM1.399 9.228a.813.813 0 00-.156-.226.798.798 0 00-.226-.156.634.634 0 00-.134-.053.73.73 0 00-.41.04.829.829 0 00-.226.155.807.807 0 00-.156.226.574.574 0 00-.038.134.6.6 0 00-.014.152.666.666 0 00.209.498.668.668 0 00.105.092.783.783 0 00.12.064.598.598 0 00.134.038.677.677 0 00.276 0 .598.598 0 00.134-.038.75.75 0 00.12-.064.678.678 0 00.106-.092.666.666 0 00.209-.498.611.611 0 000-.138.86.86 0 00-.053-.134zM.607 13.203a.783.783 0 00-.254.106.706.706 0 00.12 1.24.71.71 0 00.77-.152.705.705 0 00.199-.644.707.707 0 00-.199-.367.706.706 0 00-.636-.183zM.247 17.795a.507.507 0 00-.088.106.602.602 0 00-.106.255.593.593 0 000 .138.707.707 0 00.314.586.595.595 0 00.254.106.611.611 0 00.138 0 .667.667 0 00.498-.209.52.52 0 00.089-.105.595.595 0 00.067-.124.558.558 0 00.039-.117.609.609 0 000-.137.707.707 0 00-1.205-.499z"
      ></path>
    </svg>
  )
}
export default ListView

